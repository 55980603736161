import { Image } from "./image";
import React, { useState, useEffect } from "react";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

function importAll(r) {
  return r.keys().map(r);
}
const getImageName = (url) => {
  const parts = url.split("/");
  const fileName = parts[parts.length - 1]; // Get the last part of the path (the file name)
  return fileName.split(".")[0]; // Remove the file extension
};
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Dynamically import all images from the folder
    const imagesContext = importAll(require.context('../../public/img/portfolio', false, /\.(png|jpe?g|svg)$/));
    setImages(imagesContext);
  }, []);

  const swipeableItems = [];
  for (let i = 0; i < images.length; i++) {
    console.log(images.length);
    swipeableItems.push(
      <div key={i}>
        <Image
          title={getImageName(images[i])}
          largeImage={images[i]}
          smallImage={images[i]}
        />
      </div>
    );
  }
  // return (
  //   <div id="portfolio" className="text-center">
  //     <div className="container">
  //       <div className="section-title">
  //         <h2>Gallery</h2>
  //         <p>
  //           Glimpse of offered Products
  //         </p>
  //       </div>
  //       <div className="row">
  //           <div className="portfolio-items">
  //           <AutoPlaySwipeableViews index={index} onChangeIndex={this.handleChangeIndex>

  //           {swipeableItems.length > 0 ? swipeableItems : <p>Loading...</p>}
  //           </AutoPlaySwipeableViews>
  //           <Pagination dots={3} index={index} onChangeIndex={this.handleChangeIndex} />
  //           </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div id="portfolio" className="text-center">
      <div className="container" >
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Glimpse of offered Products
          </p>
        </div>
        <div className="row">
          <div id="photos" >
              {images ?
                images.map((image, index) => (
                  <div
                    key={index}
                  >
                    <Image
                      title={getImageName(image)}
                      largeImage={image}
                      smallImage={image}
                    />
                  </div>
                ))
                : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};
export { Gallery };
