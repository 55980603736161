import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>We offer a variety of services</p>
        </div>
        <div className="row row-no-gutters justify-content-around">
          {props.data
            ? props.data.map((d, i) => {
                // Set the column width
                const columnWidth = 4;
                // Determine if the current item should span the full width of the row
                const isSingleColumn = i === props.data.length - 1;
                // Properly format the className for the column
                const columnClassName = isSingleColumn
                  ? `col-md-${columnWidth} centered-last`
                  : `col-md-${columnWidth} mb-4`;

                return (
                  <div key={`${d.name}-${i}`} className={columnClassName}>
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                );
              })
            : "loading"}
        </div>
      </div>
    </div>
  );
};
